import React from 'react';
import '../components/layout.css';

const Content = ({ children }) => {
	return (
		<React.Fragment>
			<div id='cortex-content-index'>
				<div className='cortex-content-all-main'> {children}</div>
			</div>
		</React.Fragment>
	);
};

export default Content;
