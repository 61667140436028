import React, { useState } from 'react'
import { url } from '../constants/url';
import Layout from '../components/layout'
import Content from '../components/content'
import '../components/layout.css'
import ContentSnd from '../components/content-snd'   
import ContentFth from '../components/content-fth'
import SEO from '../components/seo'
import ContentThd from '../components/content-thd'
// import ContentFrt from '../components/content-frt'
import GlobalCTA from '../components/globalcta'
import Buddee1  from '../images/buddee-logo.svg'
import ArrowGrad from '../images/cortex-global-arrow-c.svg'
import FTW from '../images/FTW-c-min.png'
import AllCare from '../images/allcare-color-min.png'
import NorthQ from '../images/northq-c-min.png'
import Ionics from '../images/ionics-c-min.png'
import Aws from '../images/aws-c-min.png'
import Voxp from '../images/voxp-c-min.png'
import TekoL from '../images/Teko-c-min.png'
import Segment from '../images/cortex-global-segment.svg'
import Footer from '../components/footer'
import CNXhome from '../images/CNX Home 01 Trans.png'
import CNXconnex from '../images/CNX Trans.png'
// import Carousel from '../components/carousel'
// import {Link} from 'gatsby'

const Index =()=>{
  const[home,setHome]= useState(true);
  const[biz,setBiz]= useState(false);
  const[buddee,setBuddee]= useState(false);
  const[teko,setTeko]= useState(false);
    function Home(){
      setHome(true)
      setBiz(false)
      setBuddee(false)
      setTeko(false)
    }
    function Biz(){
      setBiz(true)
      setHome(false);
      setBuddee(false)
      setTeko(false)
    }
    function Buddee(){
      setBuddee(true)
      setBiz(false);
      setHome(false);
      setTeko(false)
    }
    function allBrand(){
      if (home){
        return  <React.Fragment>
               <div className="cortex-home-content-snd-all-brand-home">
                 <div className="cortex-home-content-snd-all-brand-p">
                   <img src={CNXhome} id="cortex-home-content-snd-all-brand-img" alt="CNXhomeLogo"/> 
                    <div> B2C end-to-end IoT solutions for customers</div>
                    <a href={url.link_cnx_home} target="_blank" rel="noreferrer" className="cortex-link">
                     <div id="cortex-home-content-snd-all-brand-button" > Learn More</div>
                  </a>
                  </div>
                </div> 
                <div className="cortex-home-content-snd-all-brand-home-content">
                </div>
                </React.Fragment>
      }else if (biz){
        return  <React.Fragment>
                <div className="cortex-home-content-snd-all-brand-biz">
                <div className="cortex-home-content-snd-all-brand-p">
                  <img src={CNXconnex} id="cortex-home-content-snd-all-brand-img"  alt="CNXconnexLogo"/>
                  <div>B2B end-to-end IoT solutions for enterprises</div>
                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.Cras ullamcorper lectus turpis, sit amet vestibulum tellus scelerisque nec.</p> */}
                    <a href={url.link_cnx_enterprise} target="_blank" rel="noreferrer" className="cortex-link">
                       <div id="cortex-home-content-snd-all-brand-button"> Learn More</div>
                     </a>
                  </div>
              </div>
              <div className="cortex-home-content-snd-all-brand-home-content">

              </div>
              </React.Fragment> 
      }else if (buddee){
        return  <React.Fragment>
                <div className="cortex-home-content-snd-all-brand-buddee">
                <div className="cortex-home-content-snd-all-brand-p">
                <img src={Buddee1} id="cortex-home-content-snd-all-brand-img"  alt="BuddeLogo"/>
                <div> Smart Plug that turns on any window-type air conditioner into an intelligent appliance.
                  Expand your air conditioner's capability with Buddee!
                </div>
              {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.Cras ullamcorper lectus turpis, sit amet vestibulum tellus scelerisque nec.</p> */}
                <a href={url.link_buddee} target="_blank" rel="noreferrer" className="cortex-link">
                  <div id="cortex-home-content-snd-all-brand-button"> Learn More</div>
                 </a>
                  </div>
              </div> 
              <div className="cortex-home-content-snd-all-brand-home-content">

                </div>
                </React.Fragment> 
      }else if (teko){
        return   <React.Fragment>
                <div className="cortex-home-content-snd-all-brand-teko">
                <div className="cortex-home-content-snd-all-brand-p">
                  <img src={TekoL} id="cortex-home-content-snd-all-brand-img"  alt="TekoLogo"/>
                    <div> An online platform that conviniently  connects owners of major home appliances with affordable,
                      background-checked, and certified technicians of on-site services.
                  </div>
                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.Cras ullamcorper lectus turpis, sit amet vestibulum tellus scelerisque nec.</p> */}
                    {/* <div id="cortex-home-content-snd-all-brand-button"> Learn More</div> */}
                  </div>
              </div> 
              <div className="cortex-home-content-snd-all-brand-home-content">
            </div>
            </React.Fragment> 
      }
    }
  return(
      <Layout>
        <SEO title="Cortex - Home" />
         <Content>
            <div className="cortex-home-content">
            <div id="cortex-home-content-initial-title">  Cortex Technologies Corporation</div>
              <div className="cortex-home-content-p">
                <p> Making Homes and Businesses Smarter</p>
              </div>
              </div>
          </Content>
          <ContentSnd>
          <div className="cortex-home-content-snd">      
            <div className="cortex-home-content-snd-p">
            <img src={Segment} id="cortex-home-content-segment" alt="cortexSegment"/>
            <div className="cortex-home-content-title"> Our brands </div>
              <p className="cortex-home-snd-p">We work together to build your IoT initiatives and to make things smarter. We 
              never stop learning and applying new innovations through your various brands. </p>
            </div>
            {/* <div > */}
            <div className="cortex-home-content-ul-li">
            <ul className="cortex-home-content-snd-ul-pos">             
                <li onClick={()=>Home()} className="cortex-home-content-snd-li">{home? <img src={ArrowGrad} id="cortex-home-content-arrow-gradient" style={{paddingRight:10}}  alt="arrowGradient" />:<div style={{padding:15,float:"left"}}>  </div>} Connexion for Home</li>
                <li onClick={()=>Biz()} className="cortex-home-content-snd-li">{biz? <img src={ArrowGrad}  id="cortex-home-content-arrow-gradient" style={{paddingRight:10}} alt="arrowGradient" />:<div style={{padding:15,float:"left"}}>  </div>}Connexion for Biz</li>
                <li onClick={()=>Buddee()} className="cortex-home-content-snd-li">{buddee? <img src={ArrowGrad}  id="cortex-home-content-arrow-gradient" style={{paddingRight:10}} alt="arrowGradient" />:<div style={{padding:15,float:"left"}}>  </div>}Buddee</li>
                {/* <li onClick={()=>Teko()}  className="cortex-home-content-snd-li">{teko? <img src={ArrowGrad} id="cortex-home-content-arrow-gradient" style={{paddingRight:10}} alt="arrowGradient" />:<div style={{padding:15,float:"left"}}>  </div>}Teko</li> */}
              </ul>
            </div>
            <div className="cortex-home-content-ul-li-content-1">
              <div className="cortex-home-content-ul-li-content-pos" >
            <ul>
              <li onClick={()=>Home()} style={{display:"inline-block"}} >{home? <img src={ArrowGrad}  alt="arrowGradient" />:<div style={{padding:15,float:"left"}}>  </div>}<span style={{fontSize:24}}> Connexion for Home </span></li>
              <li onClick={()=>Biz()} style={{display:"inline-block"}} >{biz? <img src={ArrowGrad}  alt="arrowGradient" />:<div style={{padding:15,float:"left"}}>  </div>}<span style={{fontSize:24}}> Connexion for Biz</span></li>
                <li onClick={()=>Buddee()} style={{display:"inline-block"}} >{buddee? <img src={ArrowGrad}   alt="arrowGradient" />:<div style={{padding:15,float:"left"}}>  </div>}<span style={{fontSize:24}}> Buddee </span></li>
                {/* <li onClick={()=>Teko()}  style={{display:"inline-block"}} >{teko? <img src={ArrowGrad} alt="arrowGradient" />:<div style={{padding:15,float:"left"}}>  </div>}<span style={{fontSize:24}}> Teko</span></li> */}
            </ul>
                </div>
            {/* <ul className="cortex-home-content-snd-ul-pos">             
                <li onClick={()=>Home()} className="cortex-home-content-snd-li">{home? <img src={ArrowGrad} id="cortex-home-content-arrow-gradient" style={{paddingRight:10}}  alt="arrowGradient" />:<div style={{padding:15,float:"left"}}>  </div>} Connexion for Home</li>
                <li onClick={()=>Biz()} className="cortex-home-content-snd-li">{biz? <img src={ArrowGrad}  id="cortex-home-content-arrow-gradient" style={{paddingRight:10}} alt="arrowGradient" />:<div style={{padding:15,float:"left"}}>  </div>}Connexion for Biz</li>
                <li onClick={()=>Buddee()} className="cortex-home-content-snd-li">{buddee? <img src={ArrowGrad}  id="cortex-home-content-arrow-gradient" style={{paddingRight:10}} alt="arrowGradient" />:<div style={{padding:15,float:"left"}}>  </div>}Buddee</li>
                <li onClick={()=>Teko()}  className="cortex-home-content-snd-li">{teko? <img src={ArrowGrad} id="cortex-home-content-arrow-gradient" style={{paddingRight:10}} alt="arrowGradient" />:<div style={{padding:15,float:"left"}}>  </div>}Teko</li>
              </ul> */}
            </div>
            <div className="cortex-home-content-ul-li-content-2">
            {allBrand()}
            </div>
              {/* <div style={{display:"inline-block",width:"50%"}}> 
                <div style={{width:"50%"}}>
              <ul >             
                <li onClick={()=>Home()} className="cortex-home-content-snd-li">{home? <img src={ArrowGrad} id="cortex-home-content-arrow-gradient"  alt="arrowGradient" />:""} Connexion for Home</li>
                <li onClick={()=>Biz()} className="cortex-home-content-snd-li">{biz? <img src={ArrowGrad}  id="cortex-home-content-arrow-gradient"  alt="arrowGradient" />:""}Connexion for Biz</li>
                <li onClick={()=>Buddee()} className="cortex-home-content-snd-li">{buddee? <img src={ArrowGrad}  id="cortex-home-content-arrow-gradient"  alt="arrowGradient" />:""}Buddee</li>
                <li onClick={()=>Teko()}  className="cortex-home-content-snd-li">{teko? <img src={ArrowGrad} id="cortex-home-content-arrow-gradient"  alt="arrowGradient" />:""}Teko</li>
              </ul>
              </div>
              <div style={{width:"50%"}}>
              {allBrand()}
              </div>
              </div> */}
              </div>
      
            {/* </div> */}
      
          </ContentSnd>
          <ContentThd>
           <div className="cortex-home-content-snd">
            <div className="cortex-home-content-snd-p">
            <img src={Segment} id="cortex-home-content-segment" alt="cortexSegment"/>
            <div className="cortex-home-content-title">Partners</div>
            <p className="cortex-home-snd-p">We work together to build your IoT initiatives and to make things smarter.
            We never stop learning and applying new innovations through our various partners. </p>
            </div>
          </div> 
          <div className="cortex-home-content-thd">
            <div className="cortex-home-content-row">
            <div className="cortex-home-content-column"><a target="_blank" rel="noopener noreferrer" href="https://www.ftwfoundation.org/"><img src={FTW} className="cortex-home-content-color" alt="FtwLogo"/></a></div>
            <div className="cortex-home-content-column"><a target="_blank" rel="noopener noreferrer" href="https://www.allcare.io/"><img src={AllCare} className="cortex-home-content-color" alt="AllCareLogo"/></a></div>
            <div className="cortex-home-content-row">
            <div className="cortex-home-content-column"> <a target="_blank" rel="noopener noreferrer" href="https://www.northq.com/"><img src={NorthQ} className="cortex-home-content-color" alt="NorthQLogo"/></a></div>
            <div className="cortex-home-content-column">   <a target="_blank" rel="noopener noreferrer" href="https://www.ionics-ems.com/"><img src={Ionics} className="cortex-home-content-color" alt="IonicsLogo"/></a> </div>
          </div>
          <div className="cortex-home-content-column"><a target="_blank" rel="noopener noreferrer" href="https://aws.amazon.com/"><img src={Aws} className="cortex-home-content-color" alt="AwsLogo"/></a></div>
           <div className="cortex-home-content-column" ><a target="_blank" rel="noopener noreferrer" href="http://voxptech.com/"><img src={Voxp} className="cortex-home-content-color" alt="VoxpLogo"/></a></div>
            <div className="cortex-home-content-column"><a target="_blank" rel="noopener noreferrer" href="https://teko.ph/"><img src={TekoL} className="cortex-home-content-color" alt="TekoLogo"/></a> </div>
            </div>
          </div>  
          </ContentThd>
          {/* <ContentFrt> 
          <div className="cortex-home-content-snd">
            <div className="cortex-home-content-snd-p">
            <img src={Segment} id="cortex-home-content-segment" alt="cortexSegment"/> 
             <div className="cortex-home-content-title"> News and events </div>
             <p className="cortex-home-snd-p">Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Cras ullamcorperlectus turpis, sit amet vestibulum tellus scelerisque nec. </p>
            </div>
            <div id="cortex-home-content-snd-all-brand-button" style={{margin:"-50px auto"}} >Read more</div>
          </div>
          <Carousel/>
          </ContentFrt>  */}
          <ContentFth>
          <GlobalCTA/>
          </ContentFth>
          <Footer/>
      </Layout>
  )
}

export default Index;